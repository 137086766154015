/* ==========================================================================
   Style Guide
   ========================================================================== */
xmp {
  display: inline;
  margin: 0;
}
/* GRID LAYOUT */
.row div.grid-layout {
  lost-utility: clearfix;
  background: $Gray;
  height: auto;
  line-height: 25px;
  font-weight: normal;
  lost-column: 1/1;
  margin-bottom: 20px;
  text-align: center;
  &.text-box {
    background: none;
    line-height: inherit;
    text-align: inherit;
  }
  &.col-9 {
    @media (min-width: $BreakPointTablet) {
      lost-column: 2/3 2;
      
    }
    &.switch {
      @media (min-width: $BreakPointTablet) {
        lost-move: 1/3;
      }
    }
  }
  &.col-3 {
    @media (min-width: $BreakPointTablet) {
      lost-column: 1/3 2;
    }
    &.switch {
      @media (min-width: $BreakPointTablet) {
        lost-move: -2/3;
      }
    }
  }
  &.col-4 {
    @media (min-width: $BreakPointTablet) {
      lost-column: 1/4 4;
    }
    &.switch {
      @media (min-width: $BreakPointTablet) {
        lost-move: -2/4 2;
      }
    }
    &.image-grid {
      margin-bottom: 10px;
    }
  }
  &.col-6 {
    @media (min-width: $BreakPointTabletSm) {
      lost-column: 1/2 2;
    }
    &.switch {
      @media (min-width: $BreakPointTabletSm) {
        lost-move: -1/2;
      }
    }
  }
}

.row.thirds div.grid-layout {
  &.col-3 {
    @media (min-width: $BreakPointTablet) {
      lost-column: 1/3 3;
    }
    &.switch-third {
      @media (min-width: $BreakPointTablet) {
        lost-move: -1/3;
      }
    }
    &.switch-forward {
      @media (min-width: $BreakPointTablet) {
        lost-move: 1/3;
      }
    }
  }
}

.row.sixths .col-2 {
  /* styleguide purposes */
  &.color-blocks {
    border: 1px solid $MediumGray;
    padding: 10px;
  }
  /* styleguide purposes */
  &.grid-full {
    border: none;
    background: $Gray;
    padding: 0;
  }
}

.row.sixths .col-2 {
  height: auto;
  line-height: 25px;
  font-weight: normal;
  lost-column: 1/1;
  text-align: left;
  margin-bottom: 20px;
  @media (min-width: $BreakPointMobile) {
    lost-column: 2/6 3;
  }
  @media (min-width: $BreakPointTablet) {
    lost-column: 1/6 6;
  }
  /* styleguide purposes */
  &.color-blocks {
    border: 1px solid $MediumGray;
    padding: 10px;
  }
  /* styleguide purposes */
  &.grid-full {
    border: none;
    background: $Gray;
    padding: 0;
  }
}

.styleguide {
  & h1.sg-h1,
  & h2.sg-h2,
  & h3.sg-h3 {
    font-family: $Mia-Light;
  }
  & h1.sg-h1 {
    border-bottom: 2px solid $Gray;
    color: $Black;
    font-size: 2em;
    & span {
      font-family: $Mia-Black;
    }
  }
  & h2.sg-h2 {
    border-bottom: 2px solid $Gray;
    color: $MediumGray;
    font-size: 1.5em;
    margin: 1.875rem 0 1rem;
  }
  & h3.sg-h3 {
    color: $MediumGray;
    font-size: 1.2em;
    border-bottom: 1px solid $Gray;
    display: inline-block;
    margin-top: 1.2em;
  }

  & h2.grid-h2 {
    font-family: $Mia-Regular;
    font-size: 1em;
    margin: 10px;
  }
  & h4.sg-h4 {
    color: $DarkGray;
    line-height: 1.3;
    margin-top: 0;
    font-size: 1em;
    &.Mia-light {
      font-family: $Mia-Light;
    }
    &.Mia-regular {
      font-family: $Mia-Regular;
    }
    &.Mia-bold {
      font-family: $Mia-Bold;
    }
    &.Mia-black {
      font-family: $Mia-Black;
    }
  }
}

.color-block {
  height: 75px;
  &.gray {
    background-color: $Gray;
  }
  &.black {
    background-color: $Black;
  }
  &.light-medium-gray {
    background-color: $LightMediumGray;
  }
  &.medium-gray {
    background-color: $MediumGray;
  }
  &.dark-gray {
    background-color: $DarkGray;
  }
  &.red {
    background-color: $Red;
  }
  &.purple {
    background-color: $Purple;
  }
  &.sea-foam {
    background-color: $SeaFoam;
  }
  &.orange {
    background-color: $orange;
  }
  &.ochre {
    background-color: $ochre;
  }
  &.goldenrod {
    background-color: $goldenrod;
  }
  &.teal {
    background-color: $teal;
  }
  &.dark-red {
    background-color: $darkRed;
  }
  &.green {
    background-color: $green;
  }
  &.bg-green {
    background-color: $Green;
  }
  &.bg-new-red {
    background-color: $NewRed;
  }
  &.bg-new-gray {
    background-color: $NewGray;
  }
  &.bg-new-purple {
    background-color: $NewPurple;
  }
  &.bg-new-blue {
    background-color: $NewBlue;
  }
}
