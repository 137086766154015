/* ==========================================================================
   Tachyons
   ========================================================================== */

.border-top-3 {
  border-top: 2px solid $Black;
}

.margin-top-1em {
  margin-top: 1em;
}
.margin-bottom-0 {
  margin-bottom: 0;
}
.margin-bottom-0-25em {
  margin-bottom: 0.25em;
}

.margin-bottom-20px {
  margin-bottom: 20px;
} 

.margin-bottom-1rem {
  margin-bottom: 1rem;
}
.margin-bottom-2rem {
  margin-bottom: 2rem;
}

.margin-top-0 {
  margin-top: 0;
}

.display-block {
  display: block;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.text-align-center {
  text-align: center;
}

.display-inline-block {
  display: inline-block;
}

.float-right {
  float: right !important;
}

.float-left {
  float: right !important;
}
