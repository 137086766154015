/* ==========================================================================
   Variables
   ========================================================================== */

$White: #fff;
$Gray: #e9e8e8;
$Black: #231f20;

/* Same as Gray but with Alpha Channel */
$LightGray: rgba(#231f20, 0.1);

/* Variant Opacities for Black */
$LightMediumGray: rgba(#231f20, 0.3);
$MediumGray: rgba(#231f20, 0.6);
$DarkGray: rgba(#231f20, 0.85);

/* -------------------------------------------------------------------------- *
 * Colors From Current Site
 * -------------------------------------------------------------------------- */

$Red: #ef3535;
$Purple: #805696;
$SeaFoam: #8cd2cd;

/* -------------------------------------------------------------------------- *
 * Colors From Branding
 * -------------------------------------------------------------------------- */

$Green: #98d789;
$NewRed: #e54c60;
$NewGray: #d4c6c3;
$NewPurple: #946db6;
$NewBlue: #48ccdf;
$LinkBlue: #1056ce;

/* -------------------------------------------------------------------------- *
 * Colors From Old Site
 * -------------------------------------------------------------------------- */

$orange: #ff6600;
$ochre: #ff9900;
$goldenrod: #fecc2e;
$teal: #1788a3;
$darkRed: #b7121a;
$green: #298658;

/* -------------------------------------------------------------------------- *
 * Media Breakpoints
 * -------------------------------------------------------------------------- */

$BreakPointDesktopXLg: 1400px;
$BreakPointDesktopLg: 1260px;
$BreakPointDesktop: 1200px;
$BreakPointTabletLg: 1120px;
$BreakPointTablet: 991px;
$BreakPointTabletSm: 768px;
$BreakPointMobile: 480px;
$BreakPointMobileSm: 480px;

/* MISCELLANEOUS */
$Transition: all .2s ease-in-out;

$BorderBlack: 1px solid $Black;

/* ANIMATIONS */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  100% {
    opacity: 1;
  }
  0% {
    opacity: 0;
  }
}
