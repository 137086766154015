/* -------------------------------------------------------------------------- *
 * Forms
 * -------------------------------------------------------------------------- */

form {
  margin-bottom: 1.5em;
  &.search-header {
    margin-bottom: 0;
  }
}
input[type=text] {
  padding: .65em;
  border-radius: 2px;
  box-shadow: inset 0 0px 0px rgba($White, 0);
  border: solid 1px $LightMediumGray;
  color: inherit;
  &::placeholder {
    color: $LightMediumGray;
  }
  &.underline {
    padding-left: 0.2em;
    border: 0;
    border-bottom: solid 1px $LightMediumGray;
  }
}
/* SEARCH */
input[type=search] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  font-family: $Mia-Light;
  font-size: 100%;
}
input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
  display: none;
}

input[type=search] {
  background: url(/dist/images/search.svg) no-repeat 8px center;
  background-size: 26px;
  border: 0;
  border-top: 1px solid rgba($Gray, 0);
  border-bottom: solid 1px rgba($Gray, 0);
  padding: .6em .4em .5em .5em;
  /* border-radius: 10em; */
  transition: $Transition;
  color: transparent;
  width: 26px;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
}
input[type=search]:focus,
input.non-focus[type=search] {
  width: 160px;
  background-color: rgba($White, 0.9);
  border-color: $LightMediumGray;
  border-top: 1px solid rgba($Gray, 0);
  padding-left: 35px;
  color: $Black;
  cursor: auto;
  background-size: 20px;
  z-index: 30000;
  &:hover {
    opacity: 1;
  }
}
input.search[type=search] {
  margin-top: 0;
  @media (max-width: $BreakPointDesktop) {
    right: 40px;
    top: -2px;
    background-size: 18px;
    padding: 0;
    &:focus {
      padding-left: 35px;
      @media (max-width: $BreakPointMobile) {
        margin-top: 1em;
        padding-bottom: .25em;
        min-width: 100%;
        width: 200px;
      }
    }
  }
  @media (max-width: 360px) {
    margin-top: .5em;
    display: block;
  }
}

input[type="checkbox"] {
  position: absolute;
  left: -9999px;
}

.checkbox-list {
  padding-left: 0;
}
input[type="checkbox"] + label.checkbox-label {
  display: block;
  position: relative;
  margin: 0;
  padding: 0 30px;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  &::before {
    transition: $Transition;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border: 2px solid $Black;
    box-shadow: 2px 2px 0 0px $Black;
    vertical-align: middle;
    margin: 0 auto;
    color: transparent;
    line-height: 0.65;
    font-size: 1.35em;
  }
}

input[type="checkbox"]:checked + label.checkbox-label::before {
  content: 'x';
  font-family: $Mia-Black;
  color: $Black;
  text-align: center;
}

input:-moz-placeholder {
  color: transparent;
}
input::-webkit-input-placeholder {
  color: transparent;
}
