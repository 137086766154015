/* ==========================================================================
   Layout Modules
   ========================================================================== */

.gray-event-block {
  background: $Gray;
  padding: 1em;
  &.block-link {
    display: block;
    border-bottom: none;
    & h4 {
      color: $Black;
      transition: $Transition;
      line-height: 1.3;
    }
    &:hover {
      border-bottom: none;
      background: $DarkGray;
      color: $White;
      & h4 {
        color: $White;
      }
    }
  }
  &.margin-bottom-small {
    margin-bottom: 0.5rem;
  }
  & h3 {
    margin-bottom: 0;
  }

  & h4 {
    margin-bottom: 0;
    font-family: $Mia-Bold;
  }

  & p {
    margin-bottom: 0;
    line-height: 1.1;
  }
}

.black-border {
  border: 0.031em solid $Black;
}

.block-padding {
  padding: 1em;
}

.small-article {
  clear: both;
  & a,
  & a:focus {
    border-bottom: 0.031em solid rgba($Black, 0);
    transition: $Transition;
    &:hover {
      border-bottom: 0.031em solid $Black;
    }
  }
}

.clearfix {
  clear: both;
}

.cell-image {
  width: 100%;
  &.thumbnail {
    width: 90px;
    float: left;
    margin-right: 1rem;
    margin-bottom: 0;
    padding-bottom: 0.5em;
  }
}

.cell-container {
  margin-bottom: 1.5rem;
  & h2 {
    margin-bottom: 1rem;

    &.padding-top {
      padding-top: 0.25em;
    }
  }
  & a.image-link {
    transition: $Transition;
    border-bottom: 0;
    &:hover {
      border-bottom: 0;
      opacity: 0.9;
    }
  }
}

.social-share-widget {
  & ul {
    margin-left: -0.35em;
  }
}