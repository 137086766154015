/* -------------------------------------------------------------------------- *
 * Buttons
 * -------------------------------------------------------------------------- */

button,
.button {
  border: 0;
  background: $Black;
  padding: 0.75em;
  font-family: $Mia-Bold;
  font-size: 0.8rem;
  cursor: pointer;
  vertical-align: middle;
  transition: $Transition;
  margin-bottom: 1.875rem;
  margin-right: 0.5em;
  color: $White;
  text-transform: uppercase;
  &:hover {
    opacity: 0.75;
  }
  &.alt-hover {
    &:hover {
      background-color: $Black;
      color: $White;
      opacity: 0.9;
    }
  }
  &.display-block {
    display: block;
  }
  &.display-inline-block {
    display: inline-block;
  }
  &.margin-top-small {
    margin-top: 2rem;
  }
  &.border-dark {
    border: 1px solid $DarkGray;
  }
  &.border-light {
    border: 1px solid $LightMediumGray;
  }
  &.border-super-light {
    border: 1px solid $Gray;
  }
  &.background-black {
    background-color: $Black;
    color: $White;
    border: none;
  }
  &.bg-none {
    background: none;
    color: $Black;
  }
  &.bg-white {
    background-color: $White;
    color: $Black;
  }
  &.bg-purple {
    background-color: $Purple;
    color: $White;
  }
  &.bg-seafoam {
    background-color: $SeaFoam;
    color: $White;
  }
  &.bg-green {
    background-color: $Green;
  }
  &.bg-new-red {
    background-color: $NewRed;
  }
  &.bg-new-gray {
    background-color: $NewGray;
  }
  &.bg-new-purple {
    background-color: $NewPurple;
  }
  &.bg-new-blue {
    background-color: $NewBlue;
  }
  &.border-none {
    border: none;
  }
  &.padding-none {
    padding: 0;
  }
  &.color-black {
    color: $Black;
  }
  &.disabled {
    opacity: 0.5;
    cursor: auto;
  }
  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }
  &.abled {
    opacity: 1;
  }
  &.bottom-border-hover {
    border-bottom: 1px solid rgba($Black, 0);
    transition: $Transition;
    opacity: 1;
    &:hover {
      border-bottom: 1px solid rgba($Black, 0.75);
    }
  }
}

a.button {
  &:hover {
    color: $White;
  }
}
a.button-link {
  &:hover {
    color: inherit;
  }
}
