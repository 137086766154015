/* -------------------------------------------------------------------------- *
 * Archive Site Fixes
 * -------------------------------------------------------------------------- */

.static {
  & .thrivent {
    margin-bottom: 10px;
  }
  & img {
    height: auto;
    max-width: 100%;
    vertical-align: middle;
  }
}

.sponsorship {
  float: left;
}

dl {
  line-height: 1.7;
}
dl:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
dt,
dd {
  display: block;
  float: left;
  margin-bottom: 0;
  vertical-align: bottom;
}
dt {
  clear: both;
  /* width: 11em; */
}

dd {
  margin-left: 0.5em;
}
img.alignnone,
.wp-caption .alignnone {
  clear: both;
}
img.alignleft,
.wp-caption .alignleft {
  float: left;
}
img.alignright,
.wp-caption .alignright {
  float: right;
}
