/* ==========================================================================
   Editor
   ========================================================================== */
/* L2 Pages */
.hero-text {
  & a,
  & a:active {
    color: $Black;
    text-decoration: none;
    transition: $Transition;
    border-bottom: 1px solid $MediumGray;
    &:hover {
      color: $DarkGray;
      border-bottom: 1px solid $LightMediumGray;
    }
  }
}

.article-container {
  lost-center: 960px 0px;
  margin-left: 0;
}

article.content-editor,
article.content-editor-image-bleed,
article.width-570 {
  width: 35.625rem; /* 570px */

  & img {
    margin-bottom: 1rem;
  }
  & p img {
    margin-bottom: 0;
  }
  & a,
  & a:active {
    color: $LinkBlue;
    text-decoration: none;
    transition: $Transition;
    border-bottom: 1px solid $LinkBlue;
    &:hover {
      color: $DarkGray;
      border-bottom: 1px solid $LightMediumGray;
    }
  }
  /* Workaround for images with links */
  & a.image-link {
    border-bottom: none;
    &:hover {
      border-bottom: none;
    }
  }
  /* Workaround for credits */
  & small.credits {
    color: $Black;
    font-style: italic;
    margin-top: -1rem;
    margin-bottom: 1rem;
    display: block;
  }
  @media (max-width: 570px) {
    width: 100%;
  }
  & .alignright {
    float: right;
    margin: 0 0 0 2rem;
  }
  & .alignleft {
    float: left;
    margin: 0 2rem 0 0;
  }
  & .aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  & .alignright,
  & .alignleft,
  & .aligncenter {
    & img {
      width: inherit;
      margin-bottom: 0;
    }
  }
}
/* Editor with Image Bleed */
article.content-editor-image-bleed > *:not(img):not(video) {
  margin-left: auto;
  margin-right: auto;
  max-width: 60rem;
  padding-left: 5%;
  padding-right: 5%;
}

article.content-editor-image-bleed {
  & .alignright {
    float: right;
    margin: 0 0 0 1em;
    margin-left: 0em;
  }
  & .alignleft {
    float: left;
    margin: 0 1em 0 0;
    margin-right: 4em;
  }
  & img {
    width: auto;
  }
}

/* WP styles from editor */
img.alignright,
.wp-caption.alignright {
  float: right;
  margin: 6px 0 1em 1em;
  width: inherit;
}
img.alignleft,
.wp-caption.alignleft {
  float: left;
  margin: 6px 1em 1em 0;
  width: inherit;
}
img.aligncenter,
div.wp-caption.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: inherit;
}
p.wp-caption-text {
  color: $Black;
  font-size: 0.8rem;
  font-style: italic;
  margin-bottom: 1rem;
  display: block;
  line-height: 1.0;
}
