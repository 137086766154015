/* ==========================================================================
   Defaults
   ========================================================================== */

html {
  box-sizing: border-box;
  /* Font Smoothing */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
dl,
blockquote,
figure,
table {
  margin: 0 0 1rem 0;
  font-weight: normal;
}

ul,
ol {
  list-style-type: none;
  padding-left: 1.5rem;
}
ol li {
  padding-left: .25rem;
}

li {
  margin: 0 0 0.25rem 0;
}
.hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
