/* ==========================================================================
   Footer Styles
   ========================================================================== */

.footer {
  width: 100%;
  margin-top: 20px;
  lost-center: 1280px 10px;
  @media (min-width: $BreakPointTablet) {
    lost-center: 1280px 20px; /* max-width: 1280px padding: 20px */
  }
  & .footer-content {
    height: 200px;
    padding: 1.5rem 0;
    font-weight: normal;
    lost-column: 1/1;
    & img {
      width: inherit;
    }
  }
}
