/* ==========================================================================
   The Grid
   ========================================================================== */

@lost rounder 100;

@lost gutter 32px; /* Margins of each cell has 32 px within the grid */

.container {
  margin-top: 60px;
  lost-center: 1280px 10px; /* max-width: 1280px padding: 100px */
  @media (min-width: $BreakPointTablet) {
    lost-center: 1280px 20px; /* max-width: 1280px padding: 10px */
  }
  @media (max-width: $BreakPointMobile) {
    margin-top: 10px;
  }
}

.row {
  lost-utility: clearfix;
}

/* GRID LAYOUT FOR LIVE SITE COMPONENTS */
.row.two-thirds div.col,
.row.thirds div.col,
.row.fourths div.col {
  lost-utility: clearfix;
  height: auto;
  font-weight: normal;
  lost-column: 1/1;
  margin-bottom: 0.5rem;
  background: none;
  line-height: inherit;
  text-align: inherit;
  &.col-9 {
    @media (min-width: $BreakPointTablet) {
      lost-column: 2/3 2;
    }
    &.switch {
      @media (min-width: $BreakPointTablet) {
        lost-move: 1/3;
      }
    }
  }
  &.col-3 {
    @media (min-width: $BreakPointTablet) {
      lost-column: 1/3 2;
    }
    &.switch {
      @media (min-width: $BreakPointTablet) {
        lost-move: -2/3;
      }
    }
    &.image-grid {
      margin-bottom: 10px;
    }
  }

  &.col-6 {
    margin-bottom: 10px;
    @media (min-width: $BreakPointTabletSm) {
      lost-column: 1/2 2;
    }
    &.switch {
      @media (min-width: $BreakPointTabletSm) {
        lost-move: -1/2;
      }
    }
  }
  &.header-grid {
    vertical-align: bottom;
    @media (max-width: $BreakPointMobile) {
      lost-column: 1/1;
      margin-bottom: 10px;
    }
    &.col-3 {
      @media (min-width: $BreakPointTabletSm) {
        lost-column: 1/3 2;
      }
    }
    &.col-9 {
      @media (min-width: $BreakPointTabletSm) {
        lost-column: 2/3 2;
      }
    }
  }
}

.row.thirds div.col {
  &.col-3 {
    @media (min-width: $BreakPointTabletSm) {
      lost-column: 2/4 2;
    }
    @media (min-width: $BreakPointTablet) {
      lost-column: 1/3 3;
    }
    &.switch {
      @media (min-width: $BreakPointTablet) {
        lost-move: -1/3;
      }
    }
    &.switch-forward {
      @media (min-width: $BreakPointTablet) {
        lost-move: 1/3;
      }
    }
  }
}
.row.thirds div.col.header-grid {
  &.col-3 {
    @media (min-width: $BreakPointMobile) {
      lost-column: 1/3 2;
    }
  }
  &.col-9 {
    @media (min-width: $BreakPointMobile) {
      lost-column: 2/3 2;
    }
  }
}
.fourths div.col {
  &.col-4 {
    @media (min-width: $BreakPointTabletSm) {
      lost-column: 2/4 2;
    }
    @media (min-width: $BreakPointTablet) {
      lost-column: 1/4 4;
    }
    &.switch {
      @media (min-width: $BreakPointTablet) {
        lost-move: -1/4;
      }
    }
    &.switch-forward {
      @media (min-width: $BreakPointTablet) {
        lost-move: 1/4;
      }
    }
  }
}
.row.sixths .col-2 {
  height: auto;
  line-height: 25px;
  font-weight: normal;
  lost-column: 1/1;
  text-align: left;
  margin-bottom: 2rem;
  @media (min-width: $BreakPointMobile) {
    lost-column: 2/6 3;
  }
  @media (min-width: $BreakPointTablet) {
    lost-column: 1/6 6;
  }
}
