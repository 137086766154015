/* ==========================================================================
   Links
   ========================================================================== */

a,
a:active {
  color: $LinkBlue;
  text-decoration: none;
  transition: $Transition;
  &:hover,
  &:focus {
    color: $Black;
  }
}
.breadcrumbs a,
.breadcrumbs a:active,
nav a,
nav a:active,
.hero-text-block a,
.hero-text-block a:active,
.hero-text,
a:active .hero-text,
a p small,
a:active p small,
a span small,
a:active span small,
footer a,
footer a:active,
h3 a,
h3 a:active,
h2 a,
h2 a:active,
h1 a,
h1 a:active,
a .date-time,
a:active .date-time,
a  p,
a:active p,
.secondary-links a,
.secondary-links a:active,
a .uppercase,
a:active .uppercase,
a .cellContent,
a:active .cellContent {
  color: $DarkGray;
  text-decoration: none;
  transition: $Transition;
  &:hover,
  &:focus {
    color: $Black;
  }
}

.view-more-link {
  border-bottom: 1px solid rgba($Black, 0);
  transition: $Transition;
  opacity: 1;
  text-transform: uppercase;
  padding: 0;
  font-family: $Mia-Bold;
  font-size: 0.8rem;
  &:hover {
    opacity: 0.8;
    border-bottom: 1px solid rgba($Black, 0.8);
  }
}

.link-brdr-gray {
  border-bottom: 1px solid $Gray;
}

.link-brdr-black {
  border-bottom: 1px solid $Black;

  & a {
    border-bottom: 1px solid $Black;
  }
}

.link-brdr-dark-gray {
  border-bottom: 1px solid $DarkGray;

  & a {
    border-bottom: 1px solid $DarkGray;
  }
}
.link-brdr-dark-gray-container {
  & a {
    border-bottom: 1px solid $DarkGray;
  }
}
.link-brdr-lt-gray,
.link-brdr-lt-gray:active {
  border-bottom: 1px solid $LightMediumGray;
}

.link-brdr-none,
.link-brdr-none:active {
  border-bottom: 1px solid rgba($Black, 0);
}

.link-fade-in {
  color: $DarkGray;
  &:hover {
    color: $Black;
  }
}

.link-fade-in-with-brdr {
  border-bottom: 1px solid $LightMediumGray;
}

.link-fade-in-brdr {
  border-bottom: 1px solid rgba($Black, 0);
  &:hover {
    border-bottom: 1px solid rgba($Black, 1);
  }
}

.link-fade-in-brdr-dark-gray {
  border-bottom: 1px solid rgba($Black, 0);
  &:hover {
    border-bottom: 1px solid $DarkGray;
  }
}

.link-fade-out {
  color: $Black;
  &:hover {
    color: $DarkGray;
  }
}

.link-fade-out-opacity {
  opacity: 1;
  &:hover {
    opacity: 0.9;
  }
}

.link-brdr-bottom-hover {
  &:hover,
  &:focus {
    border-bottom: 1px solid rgba($Black, 1);
  }
}
