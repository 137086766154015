/* ==========================================================================
   Global
   ========================================================================== */
html {
  font-size: 1em;
  /*   @media (min-width: $BreakPointDesktop) {
    font-size: 1.1em;
  }
  @media (min-width: $BreakPointDesktopXLg) {
    font-size: 1.125em;
  } */
}
html, body {
  height: 100%;
}
html {
  position: relative;
  min-height: 100%;
}
body {
  color: $Black;
  font-family: $Mia-Light;
  background: $White;
  line-height: 1.5;
  /* Flexbox in use for sticky footer  */
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1 0 auto;
}

img {
  /* width: 100%; */
  height: auto;
  max-width: 100%;
}
img[Attributes Style] {
  height: auto;
}

#root > [data-reactroot], .display-flex {
  /* Flexbox in use for sticky footer  */
  display: flex;
  flex-direction: column;
  height: 100%;
}
