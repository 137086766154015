/* ==========================================================================
   Typography
   ========================================================================== */

h1,
h2,
h3,
h4 {
  line-height: 1.1;
  font-family: $Mia-Black;
  letter-spacing: -.025em;
  color: $Black;
  & span {
    font-family: $Mia-Light;
    letter-spacing: -0.01em;
  }
}
h1 {
  font-size: 3.125rem; /* 50px */
  margin-bottom: .875rem; /* 30px */
}
h1.l2-pages {
  margin-bottom: 0;
}
h2 {
  margin-bottom: .875rem;
}
p.hero-text,
.hero-text p {
  font-size: 1.563rem; /* 25px */
  margin-bottom: 1.875rem; /* 30px */
}
h2.h2-large-alt {
  font-size: 2.188rem; /* 35px */
  font-family: $Mia-Light;
  letter-spacing: -0.01em;
}
h2.bold-small {
  font-size: 1.25rem; /* 20px */
  font-family: $Mia-Black;
  letter-spacing: -.025em;
  padding-top: .25em;
}
.cell-container h3 {
  margin-bottom: 0;
}
h3,
p {
  font-size: 1.031rem; /* 16.5px */
  line-height: 1.5;
}
h3.block-alt {
  font-size: 1.25rem; /* 20px */
  font-family: $Mia-Light;
  letter-spacing: -0.01em;
}
h4,
h5,
time {
  /* font-size: 0.688rem; */ /* 11px */
  font-size: 0.8rem;
  @media (max-width: $BreakPointMobile) {
    font-size: 1em;
  }
}

p {
  font-family: $Mia-Light;
  letter-spacing: -0.01em;
  & small {
    /* font-size: 0.688rem; */ /* 11px */
    font-size: 0.8rem;
    @media (max-width: $BreakPointMobile) {
      font-size: 1em;
    }
  }
  &.hero-text {
    line-height: 1.3;
  }
}
small {
  /* font-size: 0.688rem; */ /* 11px */
  font-size: 0.8rem;
  @media (max-width: $BreakPointMobile) {
    font-size: 1em;
  }
}
a:hover p small,
a:focus,
p small {
  color: white;
}

b,
strong {
  font-weight: 800;
  font-family: $Mia-Black;
}
.uppercase {
  text-transform: uppercase;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}
time {
  font-family: $Mia-Bold;
  margin-bottom: 1em;
  display: inline-block;
  &.Mia-light {
    font-family: $Mia-Light;
    letter-spacing: -0.01em;
  }
  &.small {
    font-size: 0.8rem;
    line-height: 1rem;
    margin-bottom: .25rem;
    @media (max-width: $BreakPointMobile) {
      font-size: 1em;
    }
  }
}
abbr[title],
abbr[data-original-title] {
  cursor: help;
  border-bottom: 1px dotted $MediumGray;
}

blockquote {
  background: none;
  border-left: 2px solid $Gray;
  margin: 1.5em 2px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
  /* line-height: 2.2; */
}
blockquote:before,
blockquote:after {
  color: $LightMediumGray;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
blockquote:after {
  color: transparent;
  content: close-quote;
  margin-left: 0.25em;
}
blockquote p {
  display: inline;
}

hr {
  border: 1px solid $Gray;
  clear: both;
  margin: 1.25em 0 1.875em;
  height: 0;
}

.list-inline {
  padding: 0;
  & li {
    display: inline-block;
    margin-right: .75rem;
    &:last-of-type {
      margin-right: 0;
    }
  }
}
.tags {
  font-size: 0.8rem;
  @media (max-width: $BreakPointMobile) {
    font-size: 1em;
  }
  & li {
    margin-right: .25rem;
  }
}

.list-padding-none {
  padding: 0;
}

.list-style-none {
  list-style: none;
}

.link-list {
  & li a {
    font-size: 0.875rem;
    /* 16.5pt */
  }
}

.Mia-light {
  font-family: $Mia-Light;
  letter-spacing: -0.01em;
}
.Mia-bold {
  font-family: $Mia-Bold;
}
.Mia-black {
  font-family: $Mia-Black;
  letter-spacing: -.025em;
}
